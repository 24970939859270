<template>
  <div>
    <el-container>
      <CommonTree
        treeTitle="组织架构"
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="false"
        :showCheckbox="false"
        :treeExpand="false"
        :expandOnClickNode="false"
        :defaultCheckedKeys="defaultCheckedKeys"
        :nodeKey="'id'"
        @getNodeClick="treeNodeClick"
      />
      <div style="width: calc(100% - 275px)">
        <head-layout
          head-title="下发任务详情"
          :head-btn-options="headBtnOptions"
          @head-cancel="headCancel"
        ></head-layout>
        <!--列表查询条件和头部按钮-->
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          v-if="gridShow"
          ref="gridLayOut"
          :table-options="tableOption"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
        >
        </grid-layout>
      </div>
    </el-container>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {deptChildTree, templatePreview} from "@/api/reportTemplate";
import {
  summaryDetailsList
} from "@/api/reportWrite";
export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      gridShow:false,
      captureTaskId: '',
      captureFormId: '',
      templateCode: '',
      treeData: [],
      properties: [],
      defaultCheckedKeys: [],
      defaultProps: {
        label: "fullName",
        value: "id",
      },
      searchTitle: 'fullName',
      headBtnOptions: [
        {
          label: "取消",
          emit: "head-cancel",
          btnOptType: "cancel",
        },
      ],
      tableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 200,
        menu: false,
        column: [
          {
            label: "任务标题",
            prop: "title",
            align: "center",
          },
          {
            label: "填报/汇总日期",
            prop: "submissionDate",
            align: "center",
          },
          {
            label: "填报/汇总截至时间",
            prop: "deadline",
            align: "center",
          },
          {
            label: "填报/汇总人员",
            prop: "person",
            align: "center",
          },
          {
            label: "填报/汇总单位",
            prop: "fullName",
            align: "center",
          },
          {
            label: "填报/汇总",
            prop: "acquisitionTaskType",
            align: "center",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=Filling_Summary`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
        ],
      },
      tableData: [],
      tableLoading: false,
      acquisitionTaskType: 'summary',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          label: '任务标题',
          prop: "title",
          span: 4,
          placeholder: '请输入任务标题'
        },
        {
          label: '下发时间',
          prop: "deliveryDate",
          type: 'date-format',
          span: 4,
          placeholder: '请选择下发时间'
        },
        {
          label: '截至时间',
          prop: "deadline",
          type: 'date-format',
          span: 4,
          placeholder: '请选择截至时间'
        },
      ],
    }
  },
  watch: {
    defaultCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".el-tree-node__content").click();
          });
        }
      },
    },
  },
  mounted() {
    this.captureFormId = this.$route.query.captureFormId;
    this.captureTaskId = this.$route.query.captureTaskId;
    this.templateCode = this.$route.query.templateCode;
    this.getTemplatePreview();
    this.getDeptChildTreeData();
  },
  methods: {
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    getTemplatePreview() {
      templatePreview(this.templateCode).then((res) => {
        console.log(res, '169');
        if (res.data.code == 200) {
          let data = res.data.data[0];
          if(typeof data == "undefined"){
            this.gridShow = true;
            return;
          }
          this.properties = data.properties;
          data.properties.forEach((item) => {
            if (item.type == 5) {
              this.tableOption.column.push({
                label: item.fieldName,
                prop: item.fieldCode,
                bind: 'rpTaskAttributeMap.' + item.fieldCode,
                align: "center",
                dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=` + item.dictionaryCode,
                props: {
                  label: 'dictValue',
                  value: 'dictKey',
                },
              })
            } else {
              this.tableOption.column.push({
                label: item.fieldName,
                prop: item.fieldCode,
                bind: 'rpTaskAttributeMap.' + item.fieldCode,
                align: "center",
              })
            }
            this.gridShow = true;
          })
        }
      })
    },
    getDeptChildTreeData() {
      deptChildTree().then((res) => {
        if (res.data.code == 200) {
          this.treeData.push(res.data.data)
          this.$nextTick(() => {
            this.defaultCheckedKeys.push(this.treeData[0].id)
          })
        }
      })
    },
    treeNodeClick(node) {
      console.log(node.id,"1111")
      this.unitId = node.id;
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
    // 搜索填报设置
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      // let cc = {
      //   title:searchForm.title,
      //   deadline:searchForm.deadlineSummaryDate,
      //   deliveryDate:searchForm.createTime
      // }
      this.onLoad(this.page, searchForm);
    },
    // 清空填报设置搜索条件
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    // 获取汇总列表数据
    onLoad(page, params) {
      this.page = page;
      this.tableLoading = false;
      params.all = true;
      summaryDetailsList(page.currentPage, page.pageSize, this.captureFormId, this.captureTaskId, this.unitId , this.templateCode,this.$route.query.templateClassify, params).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
